import React, { ReactNode, useEffect } from 'react';

import { ClassName } from '../../../../types';

import { useMainScroll } from '../../../../app/hooks/useMainScroll';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { ScrollToTop } from '../../../../helpers/scrolls/ScrollToTop';

import { keyboardPageNavigate } from '../../../../utils/keyboardPageNavigate';

interface MainScrollWrapperProps {
  children: ReactNode;
  className?: ClassName;
  withScrollRestore?: boolean;
  withScrollToTop?: boolean;
}

function MainScrollWrapper({
  children,
  className,
  withScrollRestore,
  withScrollToTop
}: MainScrollWrapperProps) {
  const {
    mainScrollElement,
    setMainScrollElement,
    restoreScrollPosition,
    isNavigateBack
  } = useMainScroll();

  const prevIsBack = usePreviousValue(isNavigateBack);

  useEffect(() => {
    window.addEventListener('keydown', keyboardPageNavigate);

    return () => {
      window.removeEventListener('keydown', keyboardPageNavigate);
    };
  }, []);

  useEffect(() => {
    if (withScrollRestore && prevIsBack && !isNavigateBack) {
      restoreScrollPosition();
    }
  }, [isNavigateBack, prevIsBack, withScrollRestore, restoreScrollPosition]);

  return (
    <main
      className={
        className ||
        'flex-1 relative overflow-y-auto focus:outline-none z-0 xl:z-15'
      }
      ref={setMainScrollElement}
    >
      {children}
      {withScrollToTop ? (
        <ScrollToTop scrollContainerElement={mainScrollElement} />
      ) : null}
    </main>
  );
}

export default MainScrollWrapper;
