import {
  FetchItemsFilterItems,
  FiltersPopoverTypesEnum
} from '../../../../../../types';

import { useIndexPageFiltersPopover } from '../../../../../common/hooks/useIndexPageFiltersPopover';
import { useFinSearchFilters } from '../../../../hooks/useFinSearchFilters';

import {
  FETCH_FIN_SEARCH_FILTERS_QUERY,
  FetchFinSearchFiltersQueryResponse
} from '../../../../queries/fetchFinSearchFilters.query';

import { SearchFilterCache } from '../../../../SearchFilterCache';
import { SearchFiltersScopes } from '../../../../searchFiltersConstants';

interface SearchFiltersPopoverOptions<FiltersType> {
  onFiltersChange?: FetchItemsFilterItems<FiltersType>;
  onClosePopover?: () => void;
  popoverTargetId?: string;
  scope: SearchFiltersScopes[];
}

function useFinSearchFiltersPopover<FiltersType>({
  onFiltersChange,
  onClosePopover,
  popoverTargetId,
  scope
}: SearchFiltersPopoverOptions<FiltersType>) {
  const {
    searchFilters,
    searchFiltersFetched,
    searchFiltersLoading,
    searchFiltersError
  } = useFinSearchFilters<FetchFinSearchFiltersQueryResponse<FiltersType>>({
    cacheKey: SearchFilterCache.indexCacheKey(),
    query: FETCH_FIN_SEARCH_FILTERS_QUERY,
    initialFilters: { scope: { in: scope }, self: true }
  });

  const {
    popoverDivRef,
    handleClosePopover,
    handleFiltersChange,
    handleToggleIsPopoverFixed
  } = useIndexPageFiltersPopover<FiltersType>({
    popoverType: FiltersPopoverTypesEnum.SAVED_FILTERS,
    onFiltersChange,
    onClosePopover,
    targetId: popoverTargetId
  });

  return {
    searchFilters,
    searchFiltersFetched,
    searchFiltersLoading,
    searchFiltersError,
    popoverDivRef,
    handleClosePopover,
    handleFiltersChange,
    handleToggleIsPopoverFixed
  };
}

export default useFinSearchFiltersPopover;
