import React, { ReactNode } from 'react';
import { ClassName } from '../../../../../types';

interface IndexTablePageHeaderProps {
  children: ReactNode;
  divClassName?: ClassName;
  actionButtons?: ReactNode;
}

function IndexTablePageHeader({
  children,
  divClassName,
  actionButtons
}: IndexTablePageHeaderProps) {
  return (
    <div
      id="page-header-default"
      className="flex flex-1 px-4 justify-between gap-4"
    >
      <div className={divClassName || 'flex-1 flex'}>{children}</div>

      {actionButtons ? (
        <div className="ml-4 flex items-center lg:ml-6">{actionButtons}</div>
      ) : null}
    </div>
  );
}

export default IndexTablePageHeader;
