const enum KeyboradEventKeys {
  HOME = 'Home',
  END = 'End',
  PAGE_DOWN = 'PageDown',
  PAGE_UP = 'PageUp'
}

function keyboardPageNavigate(event: KeyboardEvent) {
  switch (event.key) {
    case KeyboradEventKeys.HOME:
      window.scrollTo(0, 0);
      break;
    case KeyboradEventKeys.END:
      window.scrollTo(0, document.body.scrollHeight);
      break;
    case KeyboradEventKeys.PAGE_UP:
      window.scrollBy(0, -window.innerHeight);
      break;
    case KeyboradEventKeys.PAGE_DOWN:
      window.scrollBy(0, window.innerHeight);
      break;
    default:
      break;
  }
}

export default keyboardPageNavigate;
