import React, { ReactNode } from 'react';
import cl from 'classnames';

import { I18nText } from '../../../../../../../types';

import { Translate } from '../../../../../../../helpers/Translate';

interface IndexTableFilterFormFieldProps {
  children: ReactNode;
  isHidden?: boolean;
  i18nGroupLabel?: I18nText;
}

function IndexTableFilterFormFieldGroup({
  children,
  isHidden = false,
  i18nGroupLabel
}: IndexTableFilterFormFieldProps) {
  return (
    <div className={cl('px-4', { hidden: isHidden })}>
      {i18nGroupLabel ? (
        <div className="flex mb-3">
          <h6 className="font-light uppercase">
            <Translate id={i18nGroupLabel} />
          </h6>
        </div>
      ) : null}

      {children}
    </div>
  );
}

export default IndexTableFilterFormFieldGroup;
