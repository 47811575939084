import { useCallback, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';

import {
  FetchItemsClearItemsFilters,
  FetchItemsFilterItems,
  FiltersPopoverTypesEnum
} from '../../../../types';

import {
  SaveSearchFilterFunc,
  SaveSearchFilterType,
  SearchFilterNanoID
} from '../../../searchFilters/searchFiltersTypes';

import {
  UpdateSearchFilterQueryResponse,
  UPDATE_SEARCH_FILTER_QUERY
} from '../../../searchFilters/queries/updateSearchFilter.query';
import {
  CREATE_SEARCH_FILTER_QUERY,
  CreateSearchFilterQueryResponse
} from '../../../searchFilters/queries/createSearchFilter.query';

import { useIndexPageFiltersPopoverState } from '../useIndexPageFiltersPopoverState';
import { useCreateSearchFilter } from '../../../searchFilters/hooks/useCreateSearchFilter';
import { useUpdateSearchFilter } from '../../../searchFilters/hooks/useUpdateSearchFilter';

import { SearchFiltersScopes } from '../../../searchFilters/searchFiltersConstants';

interface IndexPageFiltersPopoverOptions<FiltersType> {
  scope?: SearchFiltersScopes;
  onClosePopover?: () => void;
  onSaveSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
  onFiltersChange?: FetchItemsFilterItems<FiltersType>;
  onFiltersClear?: FetchItemsClearItemsFilters;
  onResetFilters?: () => void;
  popoverType: FiltersPopoverTypesEnum;
  targetId?: string;
}

function useIndexPageFiltersPopover<FiltersType>({
  scope,
  onClosePopover,
  onSaveSearchFilter,
  onFiltersChange,
  onFiltersClear,
  onResetFilters,
  popoverType,
  targetId
}: IndexPageFiltersPopoverOptions<FiltersType>) {
  const { filtersPopoverType, setFiltersPopoverType } =
    useIndexPageFiltersPopoverState();

  const [isPopoverFixed, setIsPopoverFixed] = useState<boolean>(false);

  const handleClosePopover = useCallback<() => void>(() => {
    setFiltersPopoverType(FiltersPopoverTypesEnum.NONE);
    onClosePopover?.();
  }, [onClosePopover, setFiltersPopoverType]);

  const handleToggleIsPopoverFixed = useCallback<() => void>(
    () => setIsPopoverFixed((prevVal) => !prevVal),
    [setIsPopoverFixed]
  );

  const popoverDivRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e) => {
      const element = popoverDivRef.current;

      if (
        !isPopoverFixed &&
        element &&
        !element.contains(e.target) &&
        !includes(e.target.id, targetId)
      ) {
        handleClosePopover();
      }
    },
    [handleClosePopover, isPopoverFixed, targetId]
  );

  useEffect(() => {
    if (filtersPopoverType === popoverType) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverType, handleClickOutside, filtersPopoverType]);

  const { createSearchFilter, createSearchFilterLoading } =
    useCreateSearchFilter<FiltersType, CreateSearchFilterQueryResponse>({
      query: CREATE_SEARCH_FILTER_QUERY
    });

  const { updateSearchFilter, updateSearchFilterLoading } =
    useUpdateSearchFilter<FiltersType, UpdateSearchFilterQueryResponse>({
      query: UPDATE_SEARCH_FILTER_QUERY
    });

  const handleFiltersSave = useCallback<SaveSearchFilterFunc<FiltersType>>(
    (values, type = SaveSearchFilterType.CREATE) => {
      const valuesToSend = scope ? { ...values, scope } : values;

      if (type === SaveSearchFilterType.CREATE) {
        return createSearchFilter?.(valuesToSend)
          .then((result) => {
            onSaveSearchFilter?.(result?.createSearchFilter?.recordNanoId);
            handleClosePopover();
          })
          .catch((e) => {
            console.log(get(e, 'message'));
          });
      }
      if (type === SaveSearchFilterType.UPDATE) {
        return updateSearchFilter?.(valuesToSend)
          .then((result) => {
            onSaveSearchFilter?.(result?.updateSearchFilter?.recordNanoId);
            handleClosePopover();
          })
          .catch((e) => {
            console.log(get(e, 'message'));
          });
      }
    },
    [
      createSearchFilter,
      handleClosePopover,
      onSaveSearchFilter,
      scope,
      updateSearchFilter
    ]
  );

  const handleFiltersChange = useCallback<FetchItemsFilterItems<FiltersType>>(
    (values: FiltersType) => {
      onFiltersChange?.(values);
      handleClosePopover();
    },
    [onFiltersChange, handleClosePopover]
  );

  const handleFiltersClear = useCallback<FetchItemsClearItemsFilters>(() => {
    onFiltersClear?.();
    onResetFilters?.();
  }, [onFiltersClear, onResetFilters]);

  return {
    filtersSaving: createSearchFilterLoading || updateSearchFilterLoading,
    handleClosePopover,
    handleFiltersChange,
    handleFiltersClear,
    handleFiltersSave,
    handleToggleIsPopoverFixed,
    popoverDivRef
  };
}

export default useIndexPageFiltersPopover;
