import {
  CreateSearchFilterGqlQuery,
  CreateSearchFilterGqlStatus,
  SearchFilterColors,
  SearchFilterData,
  SearchFilterGqlError,
  SearchFilterName,
  SearchFilterNanoID,
  SearchFilterScope,
  SearchFilterUUID
} from '../../searchFiltersTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import { SearchFilterCache } from '../../SearchFilterCache';

export interface CreateSearchFilterInputType<FiltersType> {
  name: SearchFilterName | undefined;
  scope: SearchFilterScope;
  colors?: SearchFilterColors;
  data: FiltersType;
}

interface CreateSearchFilterInput {
  name: SearchFilterName;
  scope: SearchFilterScope;
  colors?: SearchFilterColors;
  data: SearchFilterData;
}

interface CreateSearchFilterResponse<CreateSearchFilterRecordType> {
  createSearchFilter: {
    status: CreateSearchFilterGqlStatus;
    recordUuid: SearchFilterUUID;
    recordNanoId: SearchFilterNanoID;
    record: CreateSearchFilterRecordType;
    errors: CreateSearchFilterErrors;
  };
}

interface CreateSearchFilterErrors {
  name: SearchFilterGqlError;
  scope: SearchFilterGqlError;
  colors: SearchFilterGqlError;
  data: SearchFilterGqlError;
  fullMessages: SearchFilterGqlError;
}

interface CreateSearchFilterOptions {
  query: CreateSearchFilterGqlQuery;
}

const action = 'createSearchFilter';

const cacheKeys = [SearchFilterCache.indexCacheKey()];

function useCreateSearchFilter<FiltersType, CreateSearchFilterRecordType>({
  query
}: CreateSearchFilterOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateSearchFilterInput,
    CreateSearchFilterResponse<CreateSearchFilterRecordType>,
    CreateSearchFilterErrors,
    CreateSearchFilterRecordType
  >({ action, cacheKeys, query });

  return {
    createSearchFilterData: createQueryData,
    createSearchFilterError: createQueryError,
    createSearchFilterLoading: createQueryLoading,
    createSearchFilterErrorMessage: createQueryErrorMessage,
    createSearchFilter: async (
      values: CreateSearchFilterInputType<FiltersType>
    ) =>
      createQuery({
        ...values,
        data: JSON.parse(JSON.stringify(values?.data))
      }),
    createSearchFilterReset: createQueryReset
  };
}

export default useCreateSearchFilter;
