import { gql } from 'graphql-request';

import {
  SearchFilterID,
  SearchFilterNanoID,
  SearchFilterUUID
} from '../searchFiltersTypes';

export interface CreateSearchFilterQueryResponse {
  id: SearchFilterID;
  uuid: SearchFilterUUID;
  nanoId: SearchFilterNanoID;
}

export const CREATE_SEARCH_FILTER_QUERY = gql`
  mutation CreateSearchFilter(
    $clientMutationId: String
    $name: String!
    $scope: String!
    $data: JSON!
    $colors: [String!]
  ) {
    createSearchFilter(
      input: {
        clientMutationId: $clientMutationId
        name: $name
        scope: $scope
        data: $data
        colors: $colors
      }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        id
        uuid
        nanoId
      }
    }
  }
`;
