import { useQueryClient } from 'react-query';

import {
  UpdateSearchFilterGqlQuery,
  UpdateSearchFilterGqlStatus,
  SearchFilterColors,
  SearchFilterData,
  SearchFilterGqlError,
  SearchFilterName,
  SearchFilterNanoID,
  SearchFilterScope,
  SearchFilterUUID
} from '../../searchFiltersTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { SearchFilterCache } from '../../SearchFilterCache';

export interface UpdateSearchFilterInputType<FiltersType> {
  uuid: SearchFilterNanoID;
  name: SearchFilterName | undefined;
  scope: SearchFilterScope;
  colors?: SearchFilterColors;
  data: FiltersType;
}

interface UpdateSearchFilterInput {
  uuid: SearchFilterNanoID;
  name: SearchFilterName;
  scope: SearchFilterScope;
  colors?: SearchFilterColors;
  data: SearchFilterData;
}

interface UpdateSearchFilterResponse<UpdateSearchFilterRecordType> {
  updateSearchFilter: {
    status: UpdateSearchFilterGqlStatus;
    recordUuid: SearchFilterUUID;
    recordNanoId: SearchFilterNanoID;
    record: UpdateSearchFilterRecordType;
    errors: UpdateSearchFilterErrors;
  };
}

interface UpdateSearchFilterErrors {
  name: SearchFilterGqlError;
  scope: SearchFilterGqlError;
  colors: SearchFilterGqlError;
  data: SearchFilterGqlError;
  fullMessages: SearchFilterGqlError;
}

interface UpdateSearchFilterOptions {
  query: UpdateSearchFilterGqlQuery;
}

interface UpdateSearchFilterCommonRecordType {
  nanoId: SearchFilterNanoID;
}

const action = 'updateSearchFilter';

const cacheKeys = [SearchFilterCache.indexCacheKey()];

function useUpdateSearchFilter<
  FiltersType,
  UpdateSearchFilterRecordType extends UpdateSearchFilterCommonRecordType
>({ query }: UpdateSearchFilterOptions) {
  const queryClient = useQueryClient();

  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useUpdateQuery<
    UpdateSearchFilterInput,
    UpdateSearchFilterResponse<UpdateSearchFilterRecordType>,
    UpdateSearchFilterErrors,
    UpdateSearchFilterRecordType
  >({
    action,
    cacheKeys,
    query,
    onSuccess(responseRecord) {
      if (responseRecord.nanoId) {
        queryClient.invalidateQueries(
          SearchFilterCache.showCacheKey(responseRecord.nanoId)
        );
      }
    }
  });

  return {
    updateSearchFilterData: updateQueryData,
    updateSearchFilterError: updateQueryError,
    updateSearchFilterLoading: updateQueryLoading,
    updateSearchFilterErrorMessage: updateQueryErrorMessage,
    updateSearchFilter: async (
      values: UpdateSearchFilterInputType<FiltersType>
    ) =>
      updateQuery({
        ...values,
        data: JSON.parse(JSON.stringify(values?.data))
      }),
    updateSearchFilterReset: updateQueryReset
  };
}

export default useUpdateSearchFilter;
