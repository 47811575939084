import { memo } from 'react';

import { AlertMessage } from '../AlertMessage';
import { ErrorMessage, I18nText } from '../../types';
import { Translate } from '../Translate';

import { words } from '../../locales/keys';

interface NoResultsProps {
  addErrorClassName?: string;
  className?: string;
  errorClassName?: string;
  errorMessage?: ErrorMessage;
  noResultsI18nText?: I18nText;
}

function NoResults({
  addErrorClassName,
  className,
  errorClassName,
  errorMessage,
  noResultsI18nText = words.noResultsYet
}: NoResultsProps) {
  return (
    <div className={className || 'text-center my-5'}>
      {errorMessage ? (
        <AlertMessage
          addClassName={addErrorClassName}
          className={errorClassName}
          message={errorMessage}
        />
      ) : (
        <Translate id={noResultsI18nText} />
      )}
    </div>
  );
}

export default memo(NoResults);
