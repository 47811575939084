import React, { ReactNode } from 'react';

import { I18nText, IsLoading } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { Form } from '../../../../../helpers/Form';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { PureSubmitButtonHelper } from '../../../../../helpers/buttons/PureSubmitButtonHelper';
import { Translate } from '../../../../../helpers/Translate';

import { words } from '../../../../../locales/keys';

interface IndexTableFilterFormProps {
  children: ReactNode;
  formHeader?: ReactNode;
  i18nCancelText?: I18nText;
  i18nGoBackHeaderText?: I18nText;
  i18nSubmitText?: I18nText;
  isLoading?: IsLoading;
  onCancel: () => void;
  onGoBack?: () => void;
  onSaveFilters?: () => void;
  onSubmit: () => void;
  saveDisabled?: boolean;
  withoutFooter?: boolean;
  withoutSaveFiltersButton?: boolean;
}

function IndexTableFilterForm({
  children,
  i18nCancelText = words.clear,
  i18nGoBackHeaderText,
  i18nSubmitText = words.search,
  isLoading,
  onCancel,
  onGoBack,
  onSaveFilters,
  onSubmit,
  saveDisabled,
  withoutFooter,
  withoutSaveFiltersButton
}: IndexTableFilterFormProps) {
  return (
    <Form
      onSubmit={onSubmit}
      className="bg-white dark:bg-gray-850 border border-gray-300 dark:border-gray-700 flex flex-col max-h-full max-w-lg overflow-hidden pointer-events-auto rounded-xl shadow-xl dark:glow-lg w-full"
    >
      <div id="filters-dropdown-header" className="flex flex-grow px-4">
        {onGoBack ? (
          <div
            id="go-back-form-header"
            className="flex-grow center relative border-b border-gray-300 dark:border-gray-700"
          >
            <PureIconButtonHelper
              className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 absolute left-0 top-3 -m-0.5"
              disabled={isLoading}
              icon={IconsEnum.ARROW_SM_LEFT_OUTLINE}
              onClick={onGoBack}
            />
            <h3 className="mx-auto py-4 text-base sm:text-lg pl-4 text-center flex-1 truncate">
              <Translate id={i18nGoBackHeaderText} />
            </h3>
          </div>
        ) : null}
      </div>

      <div id="filters-dropdown-body" className="flex-grow overflow-y-auto">
        {children}
      </div>

      {withoutFooter ? null : (
        <div id="filters-dropdown-footer" className="flex-shrink-0">
          <div className="flex items-center p-4 justify-between">
            <div className="flex items-center space-x-2">
              <PureButtonHelper
                id="btn-clear-filter"
                className="inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                disabled={isLoading}
                i18nText={i18nCancelText}
                onClick={onCancel}
              />
            </div>
            <div className="flex items-center space-x-2">
              {onSaveFilters && !withoutSaveFiltersButton ? (
                <PureButtonHelper
                  id="btn-save-filter"
                  className="inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                  disabled={saveDisabled || isLoading}
                  i18nText={words.saveFilter}
                  onClick={onSaveFilters}
                />
              ) : null}
              <PureSubmitButtonHelper
                id="btn-search-filtered"
                className="inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md shadow-sm hover:shadow-md text-white bg-blue-500 hover:bg-blue-600"
                disabled={isLoading}
                i18nText={isLoading ? words.processing : i18nSubmitText}
              />
            </div>
          </div>
        </div>
      )}
    </Form>
  );
}

export default IndexTableFilterForm;
