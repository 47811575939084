import { gql } from 'graphql-request';

import {
  SearchFilterID,
  SearchFilterNanoID,
  SearchFilterUUID
} from '../searchFiltersTypes';

export interface UpdateSearchFilterQueryResponse {
  id: SearchFilterID;
  uuid: SearchFilterUUID;
  nanoId: SearchFilterNanoID;
}

export const UPDATE_SEARCH_FILTER_QUERY = gql`
  mutation UpdateSearchFilter(
    $uuid: ID!
    $clientMutationId: String
    $name: String!
    $scope: String!
    $data: JSON!
    $colors: [String!]
  ) {
    updateSearchFilter(
      input: {
        uuid: $uuid
        clientMutationId: $clientMutationId
        name: $name
        scope: $scope
        data: $data
        colors: $colors
      }
    ) {
      errors {
        fullMessages
      }
      recordUuid
      recordNanoId
      status
      record {
        id
        uuid
        nanoId
      }
    }
  }
`;
