import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

export interface FiltersProps<FilterValue> {
  name: string;
  value: FilterValue;
  onChange: (
    arg1: { [name: string]: FilterValue },
    arg2: string[]
  ) => void | Promise<void>;
  changeOnEnter?: boolean;
}

interface FiltersOptions<FilterValue> extends FiltersProps<FilterValue> {
  debounced?: boolean;
  wait?: number;
  emptyValue: FilterValue;
}

function useFilters<FilterValue>({
  changeOnEnter = false,
  debounced,
  emptyValue,
  name,
  onChange,
  value,
  wait = 500
}: FiltersOptions<FilterValue>) {
  const [updatedValue, setValue] = useState<FilterValue>(value);
  const [cachedEmptyValue] = useState<FilterValue>(emptyValue);

  const changeValue = useCallback(
    (newValue: FilterValue) =>
      onChange(
        { [name]: newValue },
        isEqual(newValue, cachedEmptyValue) ? [name] : []
      ),
    [name, cachedEmptyValue, onChange]
  );

  const debouncedOnChange = useMemo(
    () => (debounced ? debounce(changeValue, wait) : changeValue),
    [changeValue, debounced, wait]
  );

  const handleChange = useCallback(
    (newValue: FilterValue) => {
      setValue(newValue);
      !changeOnEnter && debouncedOnChange(newValue);
    },
    [changeOnEnter, debouncedOnChange]
  );

  const handleKeyDown = useCallback<
    (e: KeyboardEvent<HTMLInputElement>) => void
  >(
    (e) => {
      if (changeOnEnter && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
        changeValue(updatedValue);
      }
    },
    [changeOnEnter, changeValue, updatedValue]
  );

  useEffect(() => {
    if (
      (isUndefined(value) || value === cachedEmptyValue) &&
      value !== updatedValue
    ) {
      setValue(cachedEmptyValue);
    }
  }, [name, value, cachedEmptyValue, setValue]);

  return {
    updatedValue,
    handleChange,
    handleKeyDown
  };
}

export default useFilters;
