import difference from 'lodash/difference';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import values from 'lodash/values';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';

function isEveryPropEmpty(
  obj: any = {},
  propsToIgnore: string[] = []
): boolean {
  const propsToCheck = isEmpty(propsToIgnore)
    ? values(obj)
    : difference(keys(obj), propsToIgnore).map((key) => get(obj, key));

  return every(
    propsToCheck,
    (value) =>
      (!value && !isNumber(value)) ||
      (isObject(value) && !isDate(value) && isEveryPropEmpty(value))
  );
}

export default isEveryPropEmpty;
