import { useCallback, useEffect, useState } from 'react';
import {
  DeepPartial,
  DefaultValues,
  FieldPath,
  PathValue,
  FieldValues,
  UnpackNestedValue
} from 'react-hook-form';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';

import { FetchItemsClearItemsFilters } from '../../../../types';

import { useReactHookForm } from '../base/useReactHookForm';

import { isEveryPropEmpty } from '../../../../utils/isEveryPropEmpty';

import { saveFilterProjectsFormSchema } from './useIndexPageFiltersForm.schema';
import {
  SaveSearchFilterType,
  SearchFilterFields
} from '../../../searchFilters/searchFiltersTypes';
import { saveFiltersFormFields } from '../../../searchFilters/searchFiltersConstants';

interface IndexPageFiltersFormOptions<FormDataType> {
  defaultValues: DefaultValues<FormDataType>;
  onClear: FetchItemsClearItemsFilters;
}

function useIndexPageFiltersForm<FormDataType extends FieldValues>({
  defaultValues,
  onClear
}: IndexPageFiltersFormOptions<FormDataType>) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    setValue,
    watch,
    resetForm,
    getValues
  } = useReactHookForm<FormDataType>({
    defaultValues,
    schema: saveFilterProjectsFormSchema
  });

  const [saveFiltersMode, setSaveFiltersMode] = useState<boolean>(false);

  const [isEditingUsers, setIsEditingUsers] = useState<boolean>(false);

  const [isEditingOtherUsers, setIsEditingOtherUsers] =
    useState<boolean>(false);

  const [isFormEmpty, setIsFormEmpty] = useState<boolean>(
    isEveryPropEmpty(omit(defaultValues, saveFiltersFormFields))
  );

  const handleSaveValue = useCallback(
    (name, value) =>
      setValue(
        name as FieldPath<FormDataType>,
        value as UnpackNestedValue<
          PathValue<FormDataType, FieldPath<FormDataType>>
        >
      ),
    [setValue]
  );

  useEffect(() => {
    handleSaveValue(
      'savingType',
      saveFiltersMode ? SaveSearchFilterType.CREATE : null
    );
  }, [saveFiltersMode, handleSaveValue]);

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === SearchFilterFields.SAVING_TYPE) {
        if (data[name] === SaveSearchFilterType.CREATE) {
          handleSaveValue('name', '');
          handleSaveValue('colors', []);
        }
        if (data[name] === SaveSearchFilterType.UPDATE) {
          handleSaveValue('name', '');
          handleSaveValue('colors', data.filterToUpdate?.colors || []);
        }
      }

      if (name === SearchFilterFields.FILTER_TO_UPDATE) {
        handleSaveValue('name', '');
        handleSaveValue('colors', data.filterToUpdate?.colors || []);
      }
    });

    return () => subscription.unsubscribe();
  }, [saveFiltersMode, watch, handleSaveValue]);

  useEffect(() => {
    const subscription = watch((data) => {
      setIsFormEmpty(isEveryPropEmpty(omit(data, saveFiltersFormFields)));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const toggleIsEditingUsers = useCallback<() => void>(
    () => setIsEditingUsers((prevState) => !prevState),
    [setIsEditingUsers]
  );

  const toggleIsEditingOtherUsers = useCallback<() => void>(
    () => setIsEditingOtherUsers((prevState) => !prevState),
    [setIsEditingOtherUsers]
  );

  const toggleSaveFiltersMode = useCallback<() => void>(
    () => setSaveFiltersMode((prevVal) => !prevVal),
    [setSaveFiltersMode]
  );

  const onClearFiltersForm = useCallback<() => void>(() => {
    onClear();
    resetForm(
      mapValues(defaultValues, () => null) as UnpackNestedValue<
        DeepPartial<FormDataType>
      >
    );
  }, [defaultValues, onClear, resetForm]);

  let onCancel = onClearFiltersForm;

  let onGoBack: (() => void) | null = null;

  if (isEditingUsers) {
    onCancel = onGoBack = toggleIsEditingUsers;
  }

  if (isEditingOtherUsers) {
    onCancel = onGoBack = toggleIsEditingOtherUsers;
  }

  if (saveFiltersMode) {
    onCancel = onGoBack = toggleSaveFiltersMode;
  }

  return {
    control,
    errors,
    handleSubmitIndexPageFiltersForm: handleSubmitReactHookForm,
    isEditingUsers,
    isEditingOtherUsers,
    isFormEmpty,
    onCancel,
    onGoBack,
    register,
    saveFiltersMode,
    setValue,
    toggleIsEditingUsers,
    toggleIsEditingOtherUsers,
    toggleSaveFiltersMode,
    getValues,
    watch
  };
}

export default useIndexPageFiltersForm;
