import {
  FetchSearchFiltersCacheKey,
  FetchSearchFilterCacheKey,
  SearchFilterNanoID
} from './searchFiltersTypes';

export class SearchFilterCache {
  static indexCacheKey() {
    return 'searchFilters' as FetchSearchFiltersCacheKey;
  }

  static showCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `searchFilter-${searchFilterNanoId}` as FetchSearchFilterCacheKey;
  }
}
