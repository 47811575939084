import isEmpty from 'lodash/isEmpty';

import { FetchItemsFilterItems } from '../../../../types';
import { SearchFilterNanoID } from '../../searchFiltersTypes';

import { useSearchFiltersPopover } from './hooks/useSearchFiltersPopover';

import { SearchFiltersListItem } from '../SearchFiltersListItem';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../helpers/Translate';

import { words } from '../../../../locales/keys';

import { SearchFiltersScopes } from '../../searchFiltersConstants';

interface SearchFiltersPopoverProps<FiltersType> {
  activeFilterNanoId?: SearchFilterNanoID;
  onFiltersChange?: FetchItemsFilterItems<FiltersType>;
  onClosePopover?: () => void;
  onDeleteActiveFilter?: () => void;
  popoverTargetId?: string;
  scope: SearchFiltersScopes[];
}

function SearchFiltersPopover<FiltersType>({
  activeFilterNanoId,
  onFiltersChange,
  onClosePopover,
  onDeleteActiveFilter,
  popoverTargetId,
  scope
}: SearchFiltersPopoverProps<FiltersType>) {
  const {
    searchFilters,
    searchFiltersFetched,
    searchFiltersError,
    popoverDivRef,
    handleClosePopover,
    handleToggleIsPopoverFixed
  } = useSearchFiltersPopover<FiltersType>({
    onFiltersChange,
    onClosePopover,
    popoverTargetId,
    scope
  });

  if (isEmpty(searchFilters) && searchFiltersFetched && !searchFiltersError) {
    return null;
  }

  return (
    <div
      data-id="search-results-wrap"
      className="absolute left-0 max-h-screen overflow-hidden pb-16 pointer-events-none pt-14 px-4 top-0 w-full"
      ref={popoverDivRef}
    >
      <div className="bg-white dark:bg-gray-850 border border-gray-300 dark:border-gray-700 flex flex-col max-h-full max-w-lg overflow-hidden pointer-events-auto rounded-xl dark:glow-lg shadow-xl w-full">
        <div
          id="search-results-dropdown-header"
          className="flex flex-shrink-0"
        />
        <div
          id="search-results-dropdown-body"
          className="flex-grow overflow-y-auto"
        >
          <div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-800 px-4">
            <div className="py-4">
              <div className="block text-gray-500 dark:text-gray-400 text-xs uppercase tracking-wide mb-1">
                <Translate id={words.savedFilters} />
              </div>
              <LoadingSkeleton count={2} loaded={searchFiltersFetched}>
                <ul>
                  {searchFilters.map(({ colors, name, nanoId, scope }) => (
                    <SearchFiltersListItem
                      active={activeFilterNanoId === nanoId}
                      color={colors?.[0]}
                      i18nText={name}
                      isPopover
                      key={nanoId}
                      onDeleteActiveFilter={onDeleteActiveFilter}
                      onSelectItem={handleClosePopover}
                      scope={scope}
                      searchFilterNanoId={nanoId}
                      toggleIsPopoverFixed={handleToggleIsPopoverFixed}
                    />
                  ))}
                </ul>

                <AlertMessage message={searchFiltersError} />
              </LoadingSkeleton>
            </div>
          </div>
        </div>
        <div
          id="search-results-dropdown-footer"
          className="flex flex-shrink-0"
        />
      </div>
    </div>
  );
}

export default SearchFiltersPopover;
