import { DeleteSearchFilterGqlQuery } from '../../searchFiltersTypes';
import {
  DeleteSearchFilterError,
  DeleteSearchFilterInput,
  DeleteSearchFilterResponse
} from './deleteSearchFilterTypes';

import { useDeleteQuery } from '../../../common/hooks/base/reactQuery/useDeleteQuery';

import { SearchFilterCache } from '../../SearchFilterCache';

interface DeleteSearchFilterOptions {
  query: DeleteSearchFilterGqlQuery;
}

const action = 'deleteSearchFilter';

const pluralScope = SearchFilterCache.indexCacheKey();

function useDeleteSearchFilter({ query }: DeleteSearchFilterOptions) {
  const {
    deleteQueryData,
    deleteQuery,
    deleteQueryReset,
    deleteQueryError,
    deleteQueryLoading,
    deleteQueryErrorMessage
  } = useDeleteQuery<
    DeleteSearchFilterInput,
    DeleteSearchFilterResponse,
    DeleteSearchFilterError
  >({ action, pluralScope, query });

  return {
    deleteSearchFilterData: deleteQueryData,
    deleteSearchFilterError: deleteQueryError,
    deleteSearchFilterLoading: deleteQueryLoading,
    deleteSearchFilterErrorMessage: deleteQueryErrorMessage,
    deleteSearchFilter: deleteQuery,
    deleteSearchFilterReset: deleteQueryReset
  };
}

export default useDeleteSearchFilter;
