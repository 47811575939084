import React, { useCallback } from 'react';

import { I18nText } from '../../../types';

import { Translate } from '../../../helpers/Translate';

type PureSubmitButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

interface PureSubmitButtonHelperDefaultProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  onClick?: PureSubmitButtonHelperOnClick;
  onMouseEnter?: () => void;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
}

interface PureSubmitButtonHelperWithI18nProps {
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureSubmitButtonHelperWithTextProps {
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureSubmitButtonHelperProps = PureSubmitButtonHelperDefaultProps &
  (PureSubmitButtonHelperWithI18nProps | PureSubmitButtonHelperWithTextProps);

function PureSubmitButtonHelper({
  className,
  disabled,
  id,
  onClick,
  onMouseEnter,
  onPointerDown,
  text,
  i18nText,
  i18nTextClassName
}: PureSubmitButtonHelperProps) {
  {
    const handleClick = useCallback<PureSubmitButtonHelperOnClick>(
      (e) => onClick?.(e),
      [onClick]
    );

    return (
      <button
        id={id}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onPointerDown={onPointerDown}
        type="submit"
      >
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
      </button>
    );
  }
}

export default PureSubmitButtonHelper;
