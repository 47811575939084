import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  FetchItemsGqlQuery,
  FetchItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemGqlQuery,
  DeleteItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlStatus,
  DeleteItemGqlStatus,
  ItemGqlError,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsErrorMessage,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSort,
  FetchItemsTotalCount,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSortItems,
  FetchItemsShowItem,
  FetchItemsEditItem,
  HexColor,
  ModelBrand,
  NanoID
} from '../../types';
import { CreateSearchFilterInputType } from './hooks/useCreateSearchFilter';

export type SearchFilterID = ID;
export type SearchFilterUUID = UUID;
export type SearchFilterNanoID = ModelBrand<NanoID, 'SearchFilterNanoID'>;
export type SearchFilterName = string;
export type SearchFilterCreatedAt = CreatedAt;
export type SearchFilterUpdatedAt = UpdatedAt;
export type SearchFilterScope = string;
export type SearchFilterData = JSON;
export type SearchFilterColors = HexColor[];

export const enum SearchFilterFields {
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  COLORS = 'colors',
  SAVING_TYPE = 'savingType',
  FILTER_TO_UPDATE = 'filterToUpdate'
}

export interface FetchSearchFiltersFilters {
  scope?: {
    in?: string[];
    eq?: string;
  };
  self?: boolean;
}

export enum FetchSearchFiltersSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSearchFiltersGqlQuery = FetchItemsGqlQuery;
export type FetchSearchFilterGqlQuery = FetchItemGqlQuery;
export type CreateSearchFilterGqlQuery = CreateItemGqlQuery;
export type UpdateSearchFilterGqlQuery = UpdateItemGqlQuery;
export type DeleteSearchFilterGqlQuery = DeleteItemGqlQuery;

export type CreateSearchFilterGqlStatus = CreateItemGqlStatus;
export type UpdateSearchFilterGqlStatus = UpdateItemGqlStatus;
export type DeleteSearchFilterGqlStatus = DeleteItemGqlStatus;
export type SearchFilterGqlError = ItemGqlError;

export type FetchSearchFiltersCacheKey = FetchItemsCacheKey;
export type FetchSearchFilterCacheKey = FetchItemCacheKey;

export type FetchSearchFiltersFetched = FetchItemsFetched;
export type FetchSearchFiltersErrorMessage = FetchItemsErrorMessage;
export type FetchSearchFiltersIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchSearchFiltersPage = FetchItemsPage;
export type FetchSearchFiltersLimit = FetchItemsLimit;
export type FetchSearchFiltersSort = FetchItemsSort;
export type FetchSearchFiltersTotalCount = FetchItemsTotalCount;
export type FetchSearchFiltersSortSearchFilters = FetchItemsSortItems;
export type FetchSearchFiltersPaginateSearchFilters = FetchItemsPaginateItems;
export type FetchSearchFiltersPrefetchSearchFilters = FetchItemsPrefetchItems;
export type FetchSearchFiltersShowSearchFilter = FetchItemsShowItem;
export type FetchSearchFiltersEditSearchFilter = FetchItemsEditItem;

export type FilterToUpdate = {
  value: SearchFilterNanoID;
  label: SearchFilterName;
  colors: SearchFilterColors;
};

export type CreateSearchFilterFunc<FiltersType> = (
  values: CreateSearchFilterInputType<FiltersType>
) => Promise<void>;

export const enum SaveSearchFilterType {
  CREATE = 'create',
  UPDATE = 'update'
}

interface SearchFilterInputType<FiltersType> {
  uuid: SearchFilterNanoID | undefined;
  name: SearchFilterName | undefined;
  scope: SearchFilterScope;
  colors?: SearchFilterColors;
  data: FiltersType;
}

export type SaveSearchFilterFunc<FiltersType> = (
  values: SearchFilterInputType<FiltersType>,
  type?: SaveSearchFilterType
) => Promise<void>;

export type SaveFiltersFormFieldsData = {
  savingType?: SaveSearchFilterType;
  name?: SearchFilterName;
  colors?: SearchFilterColors;
  filterToUpdate?: FilterToUpdate;
};
