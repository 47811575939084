import * as yup from 'yup';

import {
  SaveSearchFilterType,
  SearchFilterFields
} from '../../../searchFilters/searchFiltersTypes';
import { formsErrors } from '../../../../locales/keys';

export const saveFilterProjectsFormSchema = yup.object({
  [SearchFilterFields.NAME]: yup
    .string()
    .nullable()
    .when([SearchFilterFields.SAVING_TYPE], {
      is: (value: string) => value === SaveSearchFilterType.CREATE,
      then: yup.string().required(formsErrors.required)
    }),
  [SearchFilterFields.FILTER_TO_UPDATE]: yup
    .object()
    .nullable()
    .when([SearchFilterFields.SAVING_TYPE], {
      is: (value: string) => value === SaveSearchFilterType.UPDATE,
      then: yup.object().required(formsErrors.required)
    })
});
